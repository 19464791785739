import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authSlice from './auth';
import ipSlice from './ip';
import gameListSlice from './gameList';
import gamesSlice from './games';
import paymentsSlice from './payment';
import providersSlice from './providers';

const rootReducer = combineReducers({
  auth: authSlice,
  gameList: gameListSlice,
  games: gamesSlice,
  payment: paymentsSlice,
  providers: providersSlice,
  ip: ipSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
