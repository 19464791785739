import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance, {axiosInstanceOld} from '../axios';
import si from "../../utils/si";


export const getPayments = createAsyncThunk(
  'getPayments',
  async () => {
    try {
      const { data } = await axiosInstance.get('payments', {
        headers: {
          Authorization: 'Bearer onFLHXFMhcCRJAOrKKYh4C339GrdlSbW',
        },
      });
      return data;
    } catch (err) {
      return err.response.data
    }
  }
);

export const createPayment = createAsyncThunk(
  'createPayment',
  async (to, paymentType, paymentMethod, amount, { rejectWithValue }) => {
    try {
      const bodyData = {
        to: localStorage.getItem('to'),
        ti: Math.floor(Date.now() / 1000),
        paymentType,
        paymentMethod,
        currency: 'EUR',
      };
      const body = JSON.stringify({ ...bodyData, si: si(bodyData) })
      const { data } = await axiosInstanceOld.post('createPayment', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


const initialState = {
  payments: null,
  newPayment: null,
  onSuccess: null,
  errors: null,
  loaders: {
    common: false,
    editProfile: false,
    changePassword: false,
  },
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    //getPayments
    builder.addCase(getPayments.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getPayments.fulfilled, (state, { payload }) => {
      state.payments = payload;
      state.onSuccess = true;
      state.loaders.common = false;
    });
    builder.addCase(getPayments.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
    //createPayment
    builder.addCase(createPayment.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(createPayment.fulfilled, (state, { payload }) => {
      state.newPayment = payload;
      state.onSuccess = true;
      state.loaders.common = false;
    });
    builder.addCase(createPayment.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
  },
});

export default paymentsSlice.reducer;
