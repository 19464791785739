import React from "react";
import {useSelector} from "react-redux";
import GameCard from "../GameCard";
import {gameList} from "../../store/gameList/selectors";
import {myIp} from "../../store/ip/selectors";
import styles from './GameList.module.css';

const GameList = () => {
  const dateGamesList = useSelector(gameList);
  const ip = useSelector(myIp);
  return (
  <div className={styles.wrapper}>
    {dateGamesList && Object.keys(dateGamesList).map((g) =>
      <GameCard key={g} {...{...dateGamesList[g], ip}}/>
    )}
  </div>
  );
};

export default GameList;