import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import si from "../../utils/si";


export const getProvidersList = createAsyncThunk(
  'getProvidersList',
  async () => {
      const bodyData = {
        ti: Math.floor(Date.now() / 1000),
      }
      const body = JSON.stringify({ ...bodyData, si: si(bodyData) })
      const { data } = await axiosInstance.post('getproviderlist', body);
      return data;

  }
);



const initialState = {
  providersList: null,
  onSuccess: null,
  errors: null,
  loaders: false,
};

const providersSlice = createSlice({
  name: 'providers',
  initialState,

  extraReducers: (builder) => {
    //getProvidersList
    builder.addCase(getProvidersList.pending, (state) => {
      state.loaders = true;
    });
    builder.addCase(getProvidersList.fulfilled, (state, { payload }) => {
      state.providersList = payload.data;
      state.onSuccess = true;
      state.loaders = false;
    });
    builder.addCase(getProvidersList.rejected, (state, action) => {
      state.loaders = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
  },
});

export default providersSlice.reducer;
